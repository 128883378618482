var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view-auth" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.activeModal.modal,
            expression: "!activeModal.modal",
          },
        ],
      },
      [
        _c(
          "a",
          {
            staticClass: "modalcloseButton",
            on: { click: _vm.goPreviousRouteOrHome },
          },
          [_c("icon", { attrs: { name: "close" } })],
          1
        ),
        _c(
          "a",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.step === 1 && _vm.selectedAccountType !== null,
                expression: "step === 1 && selectedAccountType !== null ",
              },
            ],
            staticClass: "modalcloseButton",
            on: {
              click: function ($event) {
                return _vm.chooseAccountType(null)
              },
            },
          },
          [_vm._v("GO BACK")]
        ),
      ]
    ),
    _vm.selectedAccountType === null
      ? _c(
          "section",
          { staticClass: "section section--fullScreen section--flexCenter" },
          [
            _c(
              "div",
              { staticClass: "container container--xs grid grid__col2" },
              [
                _c(
                  "div",
                  {
                    staticClass: "accountType",
                    on: {
                      click: function ($event) {
                        return _vm.chooseAccountType(_vm.AccountType.Artist)
                      },
                    },
                  },
                  [
                    _c("icon", {
                      staticClass: "accountType__decoration",
                      attrs: { name: "microphone-o" },
                    }),
                    _c("h1", { staticClass: "accountType__heading" }, [
                      _vm._v("I buy beats"),
                    ]),
                    _c("p", { staticClass: "accountType__description" }, [
                      _vm._v("Artists, Videomakers, Content creators"),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "accountType",
                    on: {
                      click: function ($event) {
                        return _vm.chooseAccountType(_vm.AccountType.Producer)
                      },
                    },
                  },
                  [
                    _c("icon", {
                      staticClass:
                        "accountType__decoration accountType__decoration--secondary",
                      attrs: { name: "piano-o" },
                    }),
                    _c("h1", { staticClass: "accountType__heading" }, [
                      _vm._v("I sell beats"),
                    ]),
                    _c(
                      "p",
                      {
                        staticClass:
                          "accountType__description accountType__description--secondary",
                      },
                      [_vm._v("Producers, Beatmakers, Labels")]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        )
      : _vm._e(),
    _vm.step === 1 && _vm.selectedAccountType !== null
      ? _c("div", { staticClass: "signupWrapper" }, [
          _c(
            "div",
            {
              staticClass: "signupWrapper__sidePanel",
              class: {
                "signupWrapper__sidePanel--secondary":
                  _vm.accountTypeIsProducer,
              },
            },
            [
              _c(
                "div",
                [
                  _c("icon", {
                    staticClass: "icon-logo",
                    attrs: { name: "logo-icon", width: "100%", height: "90" },
                  }),
                  _c("h1", { staticClass: "signupWrapper__brand" }, [
                    _vm._v("TheCharts"),
                  ]),
                  _c("p", [_vm._v("Give Them Music To Talk About")]),
                ],
                1
              ),
            ]
          ),
          _vm.accountTypeIsArtist
            ? _c(
                "section",
                {
                  staticClass:
                    "section section--fullScreen section--flexCenter",
                },
                [
                  _c(
                    "div",
                    { staticClass: "container container--xxs" },
                    [
                      _vm._m(0),
                      _c("ValidationObserver", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var handleSubmit = ref.handleSubmit
                                var invalid = ref.invalid
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form form--fullWidth formLayout",
                                      attrs: { autocomplete: "off" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "formGroup" },
                                        [
                                          _c("ValidationProvider", {
                                            attrs: {
                                              name: "field",
                                              rules: { required: true },
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.user
                                                                .first_name,
                                                            expression:
                                                              "user.first_name",
                                                          },
                                                          {
                                                            name: "focus",
                                                            rawName: "v-focus",
                                                            value: true,
                                                            expression: "true",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "formInput formInput--fullWidth",
                                                        class: {
                                                          hasError:
                                                            errors.length > 0,
                                                        },
                                                        attrs: {
                                                          name: "name",
                                                          type: "text",
                                                          placeholder:
                                                            "Full Name",
                                                        },
                                                        domProps: {
                                                          value:
                                                            _vm.user.first_name,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            ) {
                                                              return
                                                            }
                                                            _vm.$set(
                                                              _vm.user,
                                                              "first_name",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "formInput__hint formInput__hint--error",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "formGroup" },
                                        [
                                          _c("ValidationProvider", {
                                            attrs: {
                                              name: "field",
                                              rules: {
                                                required: true,
                                                email: true,
                                              },
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.user.email,
                                                            expression:
                                                              "user.email",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "formInput formInput--fullWidth",
                                                        class: {
                                                          hasError:
                                                            errors.length > 0,
                                                        },
                                                        attrs: {
                                                          name: "email",
                                                          type: "email",
                                                          placeholder:
                                                            "Email Address",
                                                        },
                                                        domProps: {
                                                          value: _vm.user.email,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            ) {
                                                              return
                                                            }
                                                            _vm.$set(
                                                              _vm.user,
                                                              "email",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "formInput__hint formInput__hint--error",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "formGroup" },
                                        [
                                          _c("ValidationProvider", {
                                            attrs: {
                                              name: "field",
                                              rules: { required: true, min: 8 },
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.user.password,
                                                            expression:
                                                              "user.password",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "formInput formInput--fullWidth",
                                                        class: {
                                                          hasError:
                                                            errors.length > 0,
                                                        },
                                                        attrs: {
                                                          name: "password",
                                                          type: "password",
                                                          placeholder:
                                                            "Password",
                                                          autocomplete:
                                                            "new-password",
                                                        },
                                                        domProps: {
                                                          value:
                                                            _vm.user.password,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            ) {
                                                              return
                                                            }
                                                            _vm.$set(
                                                              _vm.user,
                                                              "password",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _c(
                                                        "p",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value: errors[0],
                                                              expression:
                                                                "errors[0]",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "formInput__hint formInput__hint--error",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Password must be at least 8 characters"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm.signupError
                                        ? _c(
                                            "p",
                                            { staticClass: "form__error" },
                                            [_vm._v(_vm._s(_vm.signupError))]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { staticClass: "form__submit" },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "btn btn--fullWidth btn--primary",
                                              class: { isDisabled: invalid },
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return handleSubmit(
                                                    _vm.validateFields
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Create account")]
                                          ),
                                          _c(
                                            "p",
                                            {
                                              staticClass: "termsAndConditions",
                                            },
                                            [
                                              _vm._v(
                                                "By signing up, you agree to our"
                                              ),
                                              _c(
                                                "a",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.SET_ACTIVE_MODAL(
                                                        {
                                                          modal:
                                                            _vm.ModalType
                                                              .TermsOfService,
                                                        }
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(" Terms of Service")]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c("form-social-buttons"),
                                      _c(
                                        "p",
                                        { staticClass: "form__footer" },
                                        [
                                          _vm._v("Already have an account? "),
                                          _c(
                                            "router-link",
                                            {
                                              staticClass: "link",
                                              attrs: { to: { name: "Login" } },
                                            },
                                            [_vm._v("Log in")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          893074427
                        ),
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm.accountTypeIsProducer
            ? _c(
                "section",
                {
                  staticClass:
                    "section section--fullScreen section--flexCenter",
                },
                [
                  _c(
                    "div",
                    { staticClass: "container container--xxs" },
                    [
                      _c("ValidationObserver", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var handleSubmit = ref.handleSubmit
                                var invalid = ref.invalid
                                return [
                                  _c(
                                    "form",
                                    {
                                      staticClass:
                                        "form formLayout form--orange",
                                      attrs: {
                                        name: "signup",
                                        autocomplete: "off",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "form__title h-textLeft",
                                        },
                                        [
                                          _c("h3", [
                                            _vm._v(
                                              "Get started absolutely free."
                                            ),
                                          ]),
                                          _c("p", { staticClass: "p--lead" }, [
                                            _vm._v(
                                              "Become a TheCharts producer."
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "formGroup" },
                                        [
                                          _c("ValidationProvider", {
                                            attrs: {
                                              name: "field",
                                              rules: { required: true },
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.user
                                                                .first_name,
                                                            expression:
                                                              "user.first_name",
                                                          },
                                                          {
                                                            name: "focus",
                                                            rawName: "v-focus",
                                                            value: true,
                                                            expression: "true",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "formInput formInput--fullWidth",
                                                        class: {
                                                          hasError:
                                                            errors.length > 0,
                                                        },
                                                        attrs: {
                                                          name: "name",
                                                          type: "text",
                                                          placeholder:
                                                            "Full Name",
                                                        },
                                                        domProps: {
                                                          value:
                                                            _vm.user.first_name,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            ) {
                                                              return
                                                            }
                                                            _vm.$set(
                                                              _vm.user,
                                                              "first_name",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "formInput__hint formInput__hint--error",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "formGroup" },
                                        [
                                          _c("ValidationProvider", {
                                            attrs: {
                                              name: "field",
                                              rules: { required: true },
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.user
                                                                .producer_name,
                                                            expression:
                                                              "user.producer_name",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "formInput formInput--fullWidth",
                                                        class: {
                                                          hasError:
                                                            errors.length > 0,
                                                        },
                                                        attrs: {
                                                          name: "producer-name",
                                                          type: "text",
                                                          placeholder:
                                                            "Producer Name",
                                                        },
                                                        domProps: {
                                                          value:
                                                            _vm.user
                                                              .producer_name,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            ) {
                                                              return
                                                            }
                                                            _vm.$set(
                                                              _vm.user,
                                                              "producer_name",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "formInput__hint formInput__hint--error",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "formGroup" },
                                        [
                                          _c("ValidationProvider", {
                                            attrs: {
                                              name: "field",
                                              rules: {
                                                required: true,
                                                email: true,
                                              },
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.user.email,
                                                            expression:
                                                              "user.email",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "formInput formInput--fullWidth",
                                                        class: {
                                                          hasError:
                                                            errors.length > 0,
                                                        },
                                                        attrs: {
                                                          name: "email",
                                                          type: "email",
                                                          placeholder:
                                                            "Email Address",
                                                        },
                                                        domProps: {
                                                          value: _vm.user.email,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            ) {
                                                              return
                                                            }
                                                            _vm.$set(
                                                              _vm.user,
                                                              "email",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "formInput__hint formInput__hint--error",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "formGroup" },
                                        [
                                          _c("ValidationProvider", {
                                            attrs: {
                                              name: "field",
                                              rules: { required: true, min: 8 },
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.user.password,
                                                            expression:
                                                              "user.password",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "formInput formInput--fullWidth",
                                                        class: {
                                                          hasError:
                                                            errors.length > 0,
                                                        },
                                                        attrs: {
                                                          name: "password",
                                                          type: "password",
                                                          placeholder:
                                                            "Password",
                                                        },
                                                        domProps: {
                                                          value:
                                                            _vm.user.password,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            ) {
                                                              return
                                                            }
                                                            _vm.$set(
                                                              _vm.user,
                                                              "password",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _c(
                                                        "p",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value: errors[0],
                                                              expression:
                                                                "errors[0]",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "formInput__hint formInput__hint--error",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Password must be at least 8 characters"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "formGroup h-textLeft" },
                                        [
                                          _c("ValidationProvider", {
                                            attrs: {
                                              name: "field",
                                              rules: { required: true },
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var errors = ref.errors
                                                    return [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.isTermsOfServiceChecked,
                                                            expression:
                                                              "isTermsOfServiceChecked",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "formCheckbox",
                                                        class: {
                                                          hasError:
                                                            errors.length > 0,
                                                        },
                                                        attrs: {
                                                          id: "terms-of-service",
                                                          type: "checkbox",
                                                          name: "Terms of Service",
                                                        },
                                                        domProps: {
                                                          checked:
                                                            Array.isArray(
                                                              _vm.isTermsOfServiceChecked
                                                            )
                                                              ? _vm._i(
                                                                  _vm.isTermsOfServiceChecked,
                                                                  null
                                                                ) > -1
                                                              : _vm.isTermsOfServiceChecked,
                                                        },
                                                        on: {
                                                          change: function (
                                                            $event
                                                          ) {
                                                            var $$a =
                                                                _vm.isTermsOfServiceChecked,
                                                              $$el =
                                                                $event.target,
                                                              $$c = $$el.checked
                                                                ? true
                                                                : false
                                                            if (
                                                              Array.isArray($$a)
                                                            ) {
                                                              var $$v = null,
                                                                $$i = _vm._i(
                                                                  $$a,
                                                                  $$v
                                                                )
                                                              if (
                                                                $$el.checked
                                                              ) {
                                                                $$i < 0 &&
                                                                  (_vm.isTermsOfServiceChecked =
                                                                    $$a.concat([
                                                                      $$v,
                                                                    ]))
                                                              } else {
                                                                $$i > -1 &&
                                                                  (_vm.isTermsOfServiceChecked =
                                                                    $$a
                                                                      .slice(
                                                                        0,
                                                                        $$i
                                                                      )
                                                                      .concat(
                                                                        $$a.slice(
                                                                          $$i +
                                                                            1
                                                                        )
                                                                      ))
                                                              }
                                                            } else {
                                                              _vm.isTermsOfServiceChecked =
                                                                $$c
                                                            }
                                                          },
                                                        },
                                                      }),
                                                      _c(
                                                        "label",
                                                        {
                                                          attrs: {
                                                            for: "terms-of-service",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "I understand and agree to"
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "formLabelContainer",
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "link",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.SET_ACTIVE_MODAL(
                                                                      {
                                                                        modal:
                                                                          _vm
                                                                            .ModalType
                                                                            .TermsOfService,
                                                                      }
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Terms of Service"
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v("."),
                                                        ]
                                                      ),
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "formInput__hint formInput__hint--error",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(errors[0])
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm.signupError
                                        ? _c(
                                            "p",
                                            { staticClass: "form__error" },
                                            [_vm._v(_vm._s(_vm.signupError))]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { staticClass: "form__submit" },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "btn btn--fullWidth",
                                              class: { isDisabled: invalid },
                                              staticStyle: { color: "white" },
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return handleSubmit(
                                                    _vm.validateFields
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Create account")]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4215287542
                        ),
                      }),
                      _c(
                        "p",
                        { staticClass: "form__footer" },
                        [
                          _vm._v("Already have an account?"),
                          _c(
                            "router-link",
                            {
                              staticClass: "link",
                              attrs: { to: { name: "Login" } },
                            },
                            [_vm._v(" Log in")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ])
      : _vm.step === 2
      ? _c(
          "section",
          { staticClass: "section section--fullScreen section--flexCenter" },
          [_vm._m(1)]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form__title h-textLeft" }, [
      _c("h3", [_vm._v("Get started absolutely free.")]),
      _c("p", { staticClass: "p--lead" }, [
        _vm._v("Unlock the real power of TheCharts."),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container container--xs" }, [
      _c("h1", { staticClass: "h1__confirm" }, [_vm._v("Confirm your email")]),
      _c("p", [
        _vm._v(
          "A confirmation email has been sent to you. Click on the link in the email to activate your account to unlock all the features."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }